import Header from '../Components/header';
import Footer from '../Components/footer';
import ContactContent from '../Components/contactcontent';

function Contact() {
  return (
    <>
      <Header /><ContactContent /><Footer />
    </>
  );
}
export default Contact;