import Header from '../Components/header';
import Footer from '../Components/footer';
import DailyAvailabilityComponent from '../Components/dailyavailabilityComponent';

function DailyAvailability() {
  return (
    <>
      <Header /><DailyAvailabilityComponent /><Footer />
    </>
  );
}
export default DailyAvailability;