import Header from '../Components/header';
import Footer from '../Components/footer';
import DoctorLoginComponent from '../Components/doctorloginComponent';

function DoctorLogin() {
  return (
    <>
      <Header /><DoctorLoginComponent /><Footer />
    </>
  );
}
export default DoctorLogin;