import Header from '../Components/header';
import Footer from '../Components/footer';
import DoctorRegistrationComponent from '../Components/doctorregistrationComponent';

function DoctorRegistration() {
  return (
    <>
      <Header /><DoctorRegistrationComponent /><Footer />
    </>
  );
}
export default DoctorRegistration;