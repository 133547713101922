import Header from '../Components/header';
import Footer from '../Components/footer';
import TokenBookingListComponent from '../Components/tokenBookingListComponent';

function TokenBookingList() {
  return (
    <>
      <Header /><TokenBookingListComponent /><Footer />
    </>
  );
}
export default TokenBookingList;