

function HomeBookings(props){
  let type = localStorage.getItem('type');
  function showPop() {
    window.location.href='/customer';
  }
  return(
    <div>
    <div className="feature-icon mb-4">
      <i className="icofont-ui-clock"></i>
    </div>
    {localStorage.getItem('session_key')? (
      <>
      {type === '1'? (<>
        <span>Your booking Status</span>
        <h4 className="mb-3">Upcoming Bookings</h4>
        <a href="/tokeninfo" className="btn btn-main-2 btn-icon btn-round-full">View your Bookings<i className="icofont-simple-right ml-2  "></i></a>
        </> ):(
          <>
          <span>Your Token Status</span>
          <h4 className="mb-3">Upcoming tokens</h4>
          <a href="/token-list" className="btn btn-main-2 btn-icon btn-round-full">View your Tokens<i className="icofont-simple-right ml-2  "></i></a>
          </> 
        )}
      </>
    ):(<>
       <span>Bookings</span>
          <h4 className="mb-3">Upcoming Bookings</h4>
          <p className="mb-4">Please login to view your bookings</p>
          <a onClick={showPop} className="btn btn-main-2 btn-icon btn-round-full">View Your Bookings<i className="icofont-simple-right ml-2  "></i></a>
    </>)}
  </div>
  );
}

export default HomeBookings;