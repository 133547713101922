import Header from '../Components/header';
import Footer from '../Components/footer';
import MyProfile from '../Components/MyProfile';

function Profile_s() {
  return (
    <>
      <Header /><MyProfile /><Footer />
    </>
  );
}
export default Profile_s;