import React from 'react'
import { useState, useEffect } from 'react';
import OTPInput from './OTPComponents/OTPInput';

const Timer = (props) => {
	const [timer, setTimer] = useState(60);
  const [isResendVisible, setIsResendVisible] = useState(false);
  const [otp, setOtp] = useState(null);

	useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearTimeout(countdown);
    } else {
      setIsResendVisible(true);
    }
  }, [timer]);

	const handleOtp = (otp) => {
    setOtp(otp);
    props.submitOTP(otp);
  };

  const handleResend = async () => {
      setTimer(60);
      setIsResendVisible(false);
      setOtp('');
      props.resendOTP();
  };

	const validateForm = () => {
    // if (!otp || !/^\d{6}$/.test(otp)) {
      
    //   return false;
    // }
    return true;
  };

	const handleVerify = async (e) => {
		
	}

	const changeEmail = () => {
    props.editEmail();
  }

	return (
		<>
			
        <p className="headtag" style={{ fontSize: "1rem" }}>
          Enter your OTP below
        </p>
        <OTPInput length={6} onComplete={handleOtp} />
        <div className="row">
          <div className='col-md-4'>
					  <p className="sign-inout-text" style={{ textAlign: "left" }}>
              <a onClick={() => changeEmail()} className='change-email test'>Change Email</a>
            </p>
          </div>
          <div className='col-md-8'>
            <p className="sign-inout-text" style={{ color: "black", textAlign: "right" }}>
            Didn’t receive OTP?{" "}
            {isResendVisible ? (
              <span
                className="resent-btn-text"
                style={{ cursor: "pointer" }}
                onClick={handleResend}
              >
                Resend
              </span>
            ) : (
              <span className="sign-inout-text" style={{ color: "black" }}>
                <span className="resent-btn-text" style={{ opacity: 0.8 }}>
                  Resend
                </span>{" "}
                in
                <span style={{ display: "inline-block", width: 37 }}>
                  0:{timer < 10 ? `0${timer}` : timer}
                </span>
              </span>
            )}
          </p>
        </div>
        </div>
		</>
	)
}

export default Timer;