import Header from '../Components/header';
import Footer from '../Components/footer';
import CategoryContent from '../Components/categorycontent';

function Category() {
  return (
    <>
      <Header /><CategoryContent /><Footer />
    </>
  );
}
export default Category;