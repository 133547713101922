/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/iframe-has-title */
import '../Style.css';
import ProfileData from './profiledata';
import DailyAvailabilityContent from './dailyavailabilitycontent';
import {useParams} from "react-router-dom";
import ReactDOM from 'react-dom';
import { useState,useEffect } from 'react';
import Loader from './loader';
import NoresultsFound from './NoresultsFound';
import RestHelper from '../RestHelper';
function ClientProfileContent() {
    let params = useParams();
    let slug = params.slug;
    let url_date = params.the_date;
    const [loading,IsLoad] = useState(true);
    useEffect(() => {
        getclientdetails(slug);
    }, []);

    async function getclientdetails(slug) {
        let apiname = "clientData";
        let method = "post";
        let body = {
            client_slug: slug,
            search_date: url_date
        };
        try {
        let response = await RestHelper(apiname, method, body);
        if (response) {
            IsLoad(false);
            ReactDOM.render(<ProfileData search_date={url_date} details={response.result} future={response.future_availability} />,document.getElementById("appendResult"));
        }
            IsLoad(false);
        } catch (err) {
            console.log(err);
            IsLoad(false);
        }
    }
    const loader = loading?<Loader />:null;
    return(
        <>
        <section className="page-title bg-1">
            <div className="overlay"></div>
            {loader}
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="block text-center">
                    <h1 className="text-capitalize mb-5 text-lg">Book a token</h1>
                    </div>
                </div>
                </div>
            </div>
        </section>
            <div id="appendResult">
                    
           </div>
        </>
    );
}

export default ClientProfileContent;