import Header from '../Components/header';
import Footer from '../Components/footer';
import Content from '../Components/content';

function Home() {
  return (
    <>
      <Header /><Content /><Footer />
    </>
  );
}
export default Home;