import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const RestHelper = async (apiname, method, body) => {
  const ssid = localStorage.getItem('session_key');

  // destroy session
  function sessionDestroy() {
    localStorage.removeItem('session_key');
    localStorage.removeItem('type');
    window.location.href = "/";
  }

  if (method === 'get') {
    try {
      const response = await axios.get(API_URL + apiname, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'ssid': ssid
        }
      }
      );
      return response.data
    } catch (err) {
      throw err;
    }

  } else {
    try {
      const response = await axios.post(API_URL + apiname, body, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'ssid': ssid
        }
      });
      if(response.code == 401) {
        sessionDestroy();
      }
      return response.data;
    } catch (err) {
      throw err;
    }
  }
}
export default RestHelper;