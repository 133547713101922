import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import DangerComponent from './dangerComponent';
import SuccessComponent from './successComponent';
import moment from 'moment';
import Loader from './loader';
import RestHelper from "../RestHelper";
function showPop() {
  window.location.href='/customer';
}


function TokenDetailsComponent() {
  let params = useParams();
  const [customer_name, setCustomerName] = useState('');
  const [slotDetails, setSlotDetails] = useState('');
  const [date] = useState(params.the_date);
  const [heading, setHeading] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [tokenNumber, setTokenNumber] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [skippedTotal, setSkippedTotal] = useState(0);

  let slot = params.slot;
  const [loading, IsLoad] = useState(false);
  
  useEffect(() => {
    getBookingInfo(date);
 }, []);
  async function getBookingInfo(date) {
    IsLoad(true);
    let apiname = "getBookingInfo";
    let method = "post";
    let body = {
      date: date
    };
    try {
      let response = await RestHelper(apiname, method, body);
      if (response) {
        setIsDisabled(false);
        let details = response.customDataObject;
        let slotDetailsArray = [];
        if (slot === 'M') {
          slotDetailsArray = {
            slot: details.morning,
            slot_start: details.morning_start,
            slot_end:details.morning_end,             
            slot_livetoken: details.live_token_m,
            slot_total_tokens: details.token_nos_m,
            slot_remainingtokens: details.remaining_token_m
          }
        } else if (slot === 'A') {
          slotDetailsArray = {
            slot: details.afternoon,
            slot_start: details.afternoon_start,
            slot_end:details.afternoon_end,             
            slot_livetoken: details.live_token_a,
            slot_total_tokens: details.token_nos_a,
            slot_remainingtokens: details.remaining_token_a
          }
        } else {
          slotDetailsArray = {
            slot: details.evening,
            slot_start: details.evening_start,
            slot_end:details.evening_end,             
            slot_livetoken: details.live_token_e,
            slot_total_tokens: details.token_nos_e,
            slot_remainingtokens: details.remaining_token_e
          }
          
        }
        let head = slotDetailsArray.slot + ' Slot';
        setHeading(head);
        setSlotDetails(slotDetailsArray);
        if(slotDetailsArray.slot_remainingtokens == 0) {
          NextToken(date, slot);
          setTokenNumber(slotDetailsArray.slot_total_tokens);
          setIsDisabled(true);
        }
      }
      IsLoad(false);
    } catch (err) {
      console.log(err);
      IsLoad(false);
    }
  }
  async function NextToken(token_date, slot) {
    IsLoad(true);
    let tokenID = document.getElementById("token_number");
    let token_no = tokenID.textContent;
    let apiname = "getNextToken";
    let method = "post";
    let body = {
      token_no: token_no,
      token_date: token_date,
      slot: slot
    };
    try {
      let response = await RestHelper(apiname, method, body);
      if (response) {
        if (response.code === 200) {
          setTokenNumber(response.next_token);
          if(slotDetails.slot_remainingtokens) {
            slotDetails.slot_remainingtokens = response.remains;
          }
          setCustomerName(response.customer);
          setSkippedTotal(response.skipped_tokens);
        } else if (response.code === 401) {
          setTokenNumber(response.next_token);
          setSuccessMessage('');
          setErrorMessage(response.message);
          showPop();
        } else {
          // setTokenNumber(response.next_token);
          if(slotDetails.slot_remainingtokens) {
            slotDetails.slot_remainingtokens = response.remains;
          }
          tokenID.classList.add("available-text-red");
          setSuccessMessage(response.message);
          setErrorMessage('');
          setSkippedTotal(response.skipped_tokens);
        }
      }
      IsLoad(false);
    } catch (err) {
      console.log(err);
      IsLoad(false);
    }
  }
  async function SkipToken(token_date, slot) {
    IsLoad(true);
    let tokenID = document.getElementById("token_number");
    let token_no = tokenID.textContent;
    let apiname = "skipToken";
    let method = "post";
    let body = {
      token_no: token_no,
      token_date: token_date,
      slot: slot
    };
    try {
      let response = await RestHelper(apiname, method, body);
      if (response) {
        if (response.code === 200) {
          setTokenNumber(response.next_token);
          if(slotDetails.slot_remainingtokens) {
            slotDetails.slot_remainingtokens = response.remains;
          }
          setCustomerName(response.customer);
          setSkippedTotal(response.skipped_tokens);
        } else if (response.code === 401) {
          setTokenNumber(response.next_token);
          setSuccessMessage('');
          setErrorMessage(response.message);
          showPop();
        } else {
          setTokenNumber(response.next_token);
          if(slotDetails.slot_remainingtokens) {
            slotDetails.slot_remainingtokens = response.remains;
          }
          tokenID.classList.add("available-text-red");
          setSuccessMessage('');
          setErrorMessage(response.message);
          setIsDisabled(true);
          setSkippedTotal(response.skipped_tokens);
        }
      }
      IsLoad(false);
    } catch (err) {
      console.log(err);
      IsLoad(false);
    }
  }
  async function NextSkipToken(token_date, slot) {
    IsLoad(true);
    let tokenID = document.getElementById("token_number");
    let token_no = tokenID.textContent;
    let apiname = "getNextSkippedToken";
    let method = "post";
    let body = {
      token_no: token_no,
      token_date: token_date,
      slot: slot
    };
    try {
      let response = await RestHelper(apiname, method, body);
      if (response) {
        tokenID.classList.remove('available-text-red');
        if (response.code === 200) {
          setTokenNumber(response.next_token);
          if(slotDetails.slot_remainingtokens) {
            slotDetails.slot_remainingtokens = response.remains;
          }
          setCustomerName(response.customer);
          setSkippedTotal(response.skipped_tokens);
        } else if (response.code === 401) {
          setTokenNumber(response.next_token);
          setSuccessMessage('');
          setErrorMessage(response.message);
          showPop();
        } else {
          setTokenNumber(response.next_token);
          if(slotDetails.slot_remainingtokens) {
            slotDetails.slot_remainingtokens = response.remains;
          }
          tokenID.classList.add("available-text-red");
          setSuccessMessage('');
          setErrorMessage(response.message);
          setSkippedTotal(response.skipped_tokens);
        }
      }
      IsLoad(false);
    } catch (err) {
      console.log(err);
      IsLoad(false);
    }
  }
  const loader = loading ? <Loader /> : null;
  return (
    <>
      {loader}
      <section className="page-title bg-1">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block text-center">
                <h1 className="text-capitalize mb-5 text-lg">Call your Tokens</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="appoinment section">
        <div className="container token-container">
          <div className="row justify-content-center">
            <div className="col-md-8">
                    <div className="feature-item mb-5 mb-lg-0" style={{ margin: "0px", padding: "20px" }}>
                      <div className="row slot-head-section">
                        <div className="col-md-6">
                          <h2 className="slot-para">{heading}</h2>
                          <h6 className="slot-date">{moment(date).format("DD MMMM YYYY")}</h6>
                        </div>
                        <div className="col-md-6" style={{display: 'inline',alignSelf: 'center'}}>
                          <span className="slot-box">{slotDetails.slot_start} </span>
                          <span style={{color:"black"}}>to</span>
                          <span className="slot-box">{slotDetails.slot_end}</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div id="token_error">
                          {successMessage.length?(
                            <SuccessComponent message={successMessage} />
                          ):(null)}
                          {errorMessage.length?(
                            <DangerComponent message={errorMessage} />
                          ):(null)}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <h4 className="mb-3 token-quee-head" >Current Token in Queue</h4>
                        </div>
                        <div className="col-md-12">
                          <div classs="row">
                            <div className="col-md-4">
                              <div className="outer-token">
                                <div className="inner-token"><b><span id="customer" className="customer-tk-name">{customer_name}</span></b></div>
                                <div className="inner-inner-token"><b><span id="token_number" className="customer-token">{tokenNumber}</span></b></div>
                                <p className="remain"><b>Remaining Token <span id="remaining_token">{slotDetails.slot_remainingtokens}</span></b></p>
                              </div>
                            </div>
                            <div className="col-md-2"></div>
                            <div className="col-md-6">
                              <button onClick={() => NextToken(date, slot)} className="button-next-tk" disabled={isDisabled}>{tokenNumber === 0 || tokenNumber == slotDetails.slot_total_tokens?(
                                <>
                                {tokenNumber === 0? 'Start' : 'Finish'}
                                </>
                              ):'Next'} <i className="icofont-simple-right ml-2  "></i></button>
                              
                              <button onClick={() => SkipToken(date, slot)} className="button-skip-tk" disabled={isDisabled}>&nbsp;&nbsp;&nbsp;Skip&nbsp;&nbsp;&nbsp;</button>

                              <a onClick={() => NextSkipToken(date, slot)} className="button-next-skip-tk" disabled={isDisabled}><i className="icofont-simple-left ml-2  "></i> Next Skipped {skippedTotal != 0? '(' + skippedTotal + ')': null}</a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          
                        </div>
                        <div className="col-md-12">
                          
                        </div>
                        <div className="col-md-12">
                          <div className="row top-spacing">
                            <div className="col-md-6">
                              
                            </div>
                            <div className="col-md-6">
                              
                            </div>
                          </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TokenDetailsComponent;