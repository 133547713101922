/* eslint-disable no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
import SuccessComponent from './successComponent';
import DangerComponent from './dangerComponent';
import ReactDOM from 'react-dom';
import React from 'react';
import { useState } from 'react';
import Loader from './loader';
import RestHelper from '../RestHelper';

function showPop() {
	window.location.href='/customer';
}

function WeeklyAvailabilityComponent() {
	const [loading, IsLoad] = useState(false);
	window.addEventListener('load', (event) => {
		getAvailabilityByWeek();
	});
	async function getAvailabilityByWeek() {
		IsLoad(true);
		let apiname = "getAvailabilityByWeek";
    let method = "post";
    let body = {
      date: ''
    };
    try {
      let response = await RestHelper(apiname, method, body);
      if (response) {
        let dataByWeek = response.result;
				for (let i = 0; i < dataByWeek.length; i++) {
					let j = dataByWeek[i].dow;
					if (dataByWeek[i].m_available === 1) {
						document.getElementById("m_c_" + j).checked = true;
						document.getElementById('m_s_' + j).value = dataByWeek[i].morning_start;
						document.getElementById('m_e_' + j).value = dataByWeek[i].morning_end;
						document.getElementById('m_x_' + j).value = dataByWeek[i].m_maxtoken;
					}
					if (dataByWeek[i].a_available === 1) {
						document.getElementById("a_c_" + j).checked = true;
						document.getElementById('a_s_' + j).value = dataByWeek[i].afternoon_start;
						document.getElementById('a_e_' + j).value = dataByWeek[i].afternoon_end;
						document.getElementById('a_x_' + j).value = dataByWeek[i].a_maxtoken;
					}
					if (dataByWeek[i].e_available === 1) {
						document.getElementById("e_c_" + j).checked = true;
						document.getElementById('e_s_' + j).value = dataByWeek[i].evening_start;
						document.getElementById('e_e_' + j).value = dataByWeek[i].evening_end;
						document.getElementById('e_x_' + j).value = dataByWeek[i].e_maxtoken;
					}
				}
      }
      IsLoad(false);
    } catch (err) {
      console.log(err);
      IsLoad(false);
    }
	}
	function ResetFields(idx, m_a_e) {
		//document.getElementById(m_a_e).reset();
		let first = m_a_e + '_s_' + idx;
		let second = m_a_e + '_e_' + idx;
		let third = m_a_e + '_x_' + idx;
		let forth = m_a_e + '_c_' + idx;
		document.getElementById(first.toString()).value = "";
		document.getElementById(second.toString()).value = "";
		document.getElementById(third.toString()).value = "";
		document.getElementById(forth.toString()).checked = false;
	}
	function sessionDestroy() {
		localStorage.removeItem('session_key');
		localStorage.removeItem('type');
		window.location.href = "/";
	}
	async function WeeklyAvailabilitySetting() {
		let session_key = localStorage.getItem('session_key');
		ReactDOM.unmountComponentAtNode(document.getElementById('message_weekly'));
		//clear all message showing span elements
		InnerHtmlClear();
		var returnAtleaseData = checkAtLeastOne();
		if (returnAtleaseData === false) {
			var message = 'Please choose a shift';
			ReactDOM.render(<DangerComponent message={message} />, document.getElementById("message_weekly"));
			return false;
		}

		//check slot match
		let checkslotresponse = CheckDailySlot();
		if(checkslotresponse === false) {
			let message = 'There is a conflict with the entered time';
			ReactDOM.render(<DangerComponent message={message} />,document.getElementById("message_weekly"));
			window.scrollTo(0, 0);
		}

		let TF = checkValidData();
		if (TF === true && checkslotresponse === true) {
			IsLoad(true);
			let arraySub = [];
			for (let j = 1; j <= 7; j++) {
				let dow = j;
				let m_available = 0;
				let morning_start = document.getElementById("m_s_" + j).value;
				if (morning_start === '') {
					morning_start = null;
				}
				let morning_end = document.getElementById("m_e_" + j).value;
				if (morning_end === '') {
					morning_end = null;
				}
				let m_maxtoken = document.getElementById("m_x_" + j).value;
				if (m_maxtoken === '') {
					m_maxtoken = 0;
				}

				//check validation of morning shift
				if (morning_start !== null || morning_end !== null || m_maxtoken !== 0) {
					document.getElementById("m_c_" + j).checked = true;
					m_available = 1;
					var returnEvent = handleValidation(j, 'm', morning_start, morning_end, m_maxtoken);
					if (returnEvent === true) {
						let time1 = isTimeValid("m_s_" + j, "m_e_" + j);
						if (time1 === false) {
							return false;
						}
					} else {
						return false;
					}
				}

				let a_available = 0;
				let afternoon_start = document.getElementById("a_s_" + j).value;
				if (afternoon_start === '') {
					afternoon_start = null;
				}
				let afternoon_end = document.getElementById("a_e_" + j).value;
				if (afternoon_end === '') {
					afternoon_end = null;
				}
				let a_maxtoken = document.getElementById("a_x_" + j).value;
				if (a_maxtoken === '') {
					a_maxtoken = 0;
				}
				//check validation of afternoon shift
				if (afternoon_start !== null || afternoon_end !== null || a_maxtoken !== 0) {
					document.getElementById("a_c_" + j).checked = true;
					a_available = 1;
					var returnEvent = handleValidation(j, 'a', afternoon_start, afternoon_end, a_maxtoken);
					if (returnEvent === true) {
						let time2 = isTimeValid("a_s_" + j, "a_e_" + j);
						if (time2 === false) {
							return false;
						}
					} else {
						return false;
					}
				}

				let e_available = 0;
				let evening_start = document.getElementById("e_s_" + j).value;
				if (evening_start === '') {
					evening_start = null;
				}
				let evening_end = document.getElementById("e_e_" + j).value;
				if (evening_end === '') {
					evening_end = null;
				}
				let e_maxtoken = document.getElementById("e_x_" + j).value;
				if (e_maxtoken === '') {
					e_maxtoken = 0;
				}
				//check validation of evening shift
				if (evening_start !== null || evening_end !== null || e_maxtoken !== 0) {
					document.getElementById("e_c_" + j).checked = true;
					e_available = 1;
					var returnEvent = handleValidation(j, 'e', evening_start, evening_end, e_maxtoken);
					if (returnEvent === true) {
						let time3 = isTimeValid("e_s_" + j, "e_e_" + j);
						if (time3 === false) {
							return false;
						}
					} else {
						return false;
					}
				}
				
				let objectSub = {
					dow: dow, m_available: m_available, morning_start: morning_start, morning_end: morning_end, m_maxtoken: m_maxtoken,
					a_available: a_available, afternoon_start: afternoon_start, afternoon_end: afternoon_end, a_maxtoken: a_maxtoken,
					e_available: e_available, evening_start: evening_start, evening_end: evening_end, e_maxtoken: e_maxtoken
				};
				arraySub.push(objectSub);
			}
			
			let arrayObject = arraySub;
			let apiname = "default_availability";
			let method = "post";
			let body = {
				availability: arrayObject
			};
			try {
				let response = await RestHelper(apiname, method, body);
				if (response) {
					if (response.code === 200) {
						window.scrollTo(0, 0);
						let message = response.message;
						ReactDOM.render(<SuccessComponent message={message} />, document.getElementById("message_weekly"));
					} else if (response.code === 401) {
						let message = response.message;
						ReactDOM.render(<DangerComponent message={message} />, document.getElementById("message_weekly"));
						sessionDestroy();
						showPop();
					} else {
						window.scrollTo(0, 0);
						let message = response.message;
						ReactDOM.render(<DangerComponent message={message} />, document.getElementById("message_weekly"));
					}
				}
				IsLoad(false);
			} catch (err) {
				console.log(err);
				IsLoad(false);
			}

		} else {
			return false;
		}


	}
	function checkValidData() {
		for (let j = 1; j <= 7; j++) {
			let morning_start = document.getElementById("m_s_" + j).value;
			if (morning_start === '') {
				morning_start = null;
			}
			let morning_end = document.getElementById("m_e_" + j).value;
			if (morning_end === '') {
				morning_end = null;
			}
			let m_maxtoken = document.getElementById("m_x_" + j).value;
			if (m_maxtoken === '') {
				m_maxtoken = 0;
			}
			//check validation of morning shift
			if (morning_start !== null || morning_end !== null || m_maxtoken !== 0) {
				document.getElementById("m_c_" + j).checked = true;
				var returnEvent = handleValidation(j, 'm', morning_start, morning_end, m_maxtoken);
				if (returnEvent === true) {
				} else {
					return false;
				}
			}

			let afternoon_start = document.getElementById("a_s_" + j).value;
			if (afternoon_start === '') {
				afternoon_start = null;
			}
			let afternoon_end = document.getElementById("a_e_" + j).value;
			if (afternoon_end === '') {
				afternoon_end = null;
			}
			let a_maxtoken = document.getElementById("a_x_" + j).value;
			if (a_maxtoken === '') {
				a_maxtoken = 0;
			}
			//check validation of afternoon shift
			if (afternoon_start !== null || afternoon_end !== null || a_maxtoken !== 0) {
				document.getElementById("a_c_" + j).checked = true;
				var returnEvent = handleValidation(j, 'a', afternoon_start, afternoon_end, a_maxtoken);
				if (returnEvent === true) {
				} else {
					return false;
				}
			}

			let evening_start = document.getElementById("e_s_" + j).value;
			if (evening_start === '') {
				evening_start = null;
			}
			let evening_end = document.getElementById("e_e_" + j).value;
			if (evening_end === '') {
				evening_end = null;
			}
			let e_maxtoken = document.getElementById("e_x_" + j).value;
			if (e_maxtoken === '') {
				e_maxtoken = 0;
			}
			//check validation of evening shift
			if (evening_start !== null || evening_end !== null || e_maxtoken !== 0) {
				document.getElementById("e_c_" + j).checked = true;
				var returnEvent = handleValidation(j, 'e', evening_start, evening_end, e_maxtoken);
				if (returnEvent === true) {
				} else {
					return false;
				}
			}
		}
		return true;
	}
	function handleValidation(idx, m_a_e, start, end, max) {
		let first = m_a_e + '_s_' + idx;
		let firsterror = m_a_e + '_s_' + idx + '_err';
		if (document.getElementById(first.toString()).value === '') {
			IsLoad(false);
			var error1 = document.getElementById(firsterror);
			error1.innerHTML += 'Field is required';
			return false;
		}
		let second = m_a_e + '_e_' + idx;
		let seconderror = m_a_e + '_e_' + idx + '_err';
		if (document.getElementById(second.toString()).value === '') {
			IsLoad(false);
			var error2 = document.getElementById(seconderror);
			error2.innerHTML += 'Field is required';
			return false;
		}
		let third = m_a_e + '_x_' + idx;
		let thirderror = m_a_e + '_x_' + idx + '_err';
		if (document.getElementById(third.toString()).value === '') {
			IsLoad(false);
			var error3 = document.getElementById(thirderror);
			error3.innerHTML += 'Field is required';
			return false;
		}
		return true;
	}
	function InnerHtmlClear() {
		//morning clear   m_s_1_err
		for (let i = 1; i <= 7; i++) {
			var error1 = document.getElementById("m_s_" + i + "_err");
			error1.innerHTML = '';
			var error2 = document.getElementById("m_e_" + i + "_err");
			error2.innerHTML = '';
			var error3 = document.getElementById("m_x_" + i + "_err");
			error3.innerHTML = '';
			//afternoon clear
			var error4 = document.getElementById("a_s_" + i + "_err");
			error4.innerHTML = '';
			var error5 = document.getElementById("a_e_" + i + "_err");
			error5.innerHTML = '';
			var error6 = document.getElementById("a_x_" + i + "_err");
			error6.innerHTML = '';
			//evening clear
			var error7 = document.getElementById("e_s_" + i + "_err");
			error7.innerHTML = '';
			var error8 = document.getElementById("e_e_" + i + "_err");
			error8.innerHTML = '';
			var error9 = document.getElementById("e_x_" + i + "_err");
			error9.innerHTML = '';
		}

		return true;

	}
	function checkAtLeastOne() {
		const row = document.getElementById('checkAtLeastOne');
		//console.log(row);
		const inputElements = Array.from(row.querySelectorAll(['input[type=time]', 'input[type=number]']));
		//console.log(inputElements);
		const validInputs = inputElements.filter(el => el.value);
		if (!validInputs.length) {
			return false;
		} else {
			return true;
		}
	}
	function isTimeValid(start, end) {
		let start_time = document.getElementById(start).value;
		let end_time = document.getElementById(end).value;
		ReactDOM.unmountComponentAtNode(document.getElementById('message_weekly'));
		if (start_time !== '' && end_time !== '') {
			if (start_time < end_time) {
				document.getElementById("message_weekly").innerHTML = '';
				return true;
			} else {
				IsLoad(false);
				window.scrollTo(0, 0);
				var message = 'Start time should less than end time'
				ReactDOM.render(<DangerComponent message={message} />, document.getElementById("message_weekly"));
				return false;
			}
		}
	}

	//Slot validation
	// for checking slot time matches
	function CheckDailySlot(sts) {
		let response = '';
		for (let j = 1; j <= 7; j++) {
			let morning_start = document.getElementById("m_s_" + j).value;
			let morning_end = document.getElementById("m_e_" + j).value;
			let afternoon_start = document.getElementById("a_s_" + j).value;
			let afternoon_end = document.getElementById("a_e_" + j).value;
			let evening_start = document.getElementById("e_s_" + j).value;
			let evening_end = document.getElementById("e_e_" + j).value;
			if(morning_start !== '' && afternoon_start !== '' && evening_start !== '') {
				if(morning_end >= afternoon_start) {
					return false;						
				} else if(afternoon_end >= evening_start) {
					return false;
				} else {
					response = true;
				}
			} else if(morning_start !== '' && afternoon_start !== '' && evening_start === '') {
				if(morning_end >= afternoon_start) {
					return false;
				} else {
					response = true;
				}
			} else if(morning_start === '' && afternoon_start !== '' && evening_start !== '') {
				if(afternoon_end >= evening_start) {
					return false;
				} else {
					response = true;
				}
			} else if(morning_start !== '' && afternoon_start === '' && evening_start !== '') {
				if(morning_end >= evening_start) {
					return false;
				} else {
					response = true;
				}
			} else {
				response = true;
			}
		}
		return response;
	}

	const loader = loading ? <Loader /> : null;
	return (
		<>
			{loader}
			<section className="page-title bg-1">
				<div className="overlay"></div>
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="block text-center">
								<span className="text-white">Set your availability</span>
								<h1 className="text-capitalize mb-5 text-lg">Weekly Availability</h1>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="appoinment section">
				<div className="container">
					<div className="row">
						<div className="col-lg-1">
							{/* <div className="mt-3">
                        <div className="feature-icon mb-3">
                        <i className="icofont-support text-lg"></i>
                        </div>
                        <span className="h3">Call for an Emergency Service!</span>
                        <h2 className="text-color mt-3">+84 789 1256 </h2>
                    </div> */}
						</div>

						<div className="col-lg-12">
							<div className="appoinment-wrap mt-5 mt-lg-0 pl-lg-5">
								<h2 className="mb-2 title-color">Set Availability</h2>
								<div id="message_weekly">

								</div>
								<form id="#" className="appoinment-form" method="post" action="#">
									<div className="row" id="checkAtLeastOne">
										<div className="col-lg-12">
											<div className="form-group">
												<input name="m_1" value="SUNDAY" type="text" className="form-control readonly" readOnly />
											</div>
										</div>
										<div className="col-md-8">
											<div className="row">
												<div className="col-lg-1 justify-content-center d-flex flex-column">
													<input type="checkbox" className="check-class" id="m_c_1" name="m_c_1" value="1" />
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Morning Start</label>
														<input name="m_s_1" id="m_s_1" placeholder="Date" type="time" className="form-control" onChange={() => isTimeValid('m_s_1', 'm_e_1')} />
													</div>
													<span style={{ color: "red" }} id="m_s_1_err"></span>
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Morning End</label>
														<input name="m_e_1" id="m_e_1" type="time" className="form-control" onBlur={() => isTimeValid('m_s_1', 'm_e_1')} />
													</div>
													<span style={{ color: "red" }} id="m_e_1_err"></span>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="row">
												<div className="col-md-10">
													<div className="form-group">
														<label>Maximum token</label>
														<input name="m_x_1" id="m_x_1" type="number" className="form-control" />
													</div>
													<span style={{ color: "red" }} id="m_x_1_err"></span>
												</div>
												<div className="col-md-2 justify-content-center d-flex flex-column">
													<a className="reset-style" onClick={() => ResetFields('1', 'm')}>Reset</a>
												</div>
											</div>
										</div>
										<div className="col-lg-8">
											<div className="row">
												<div className="col-lg-1 justify-content-center d-flex flex-column">
													<input type="checkbox" className="check-class" id="a_c_1" name="a_c_1" value="1" />
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Afternoon Start</label>
														<input name="a_s_1" id="a_s_1" placeholder="Date" type="time" className="form-control" onBlur={() => isTimeValid('a_s_1', 'a_e_1')} />
													</div>
													<span style={{ color: "red" }} id="a_s_1_err"></span>
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Afternoon End</label>
														<input name="a_e_1" id="a_e_1" type="time" className="form-control" onBlur={() => isTimeValid('a_s_1', 'a_e_1')} />
													</div>
													<span style={{ color: "red" }} id="a_e_1_err"></span>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="row">
												<div className="col-md-10">
													<div className="form-group">
														<label>Maximum token</label>
														<input name="a_x_1" id="a_x_1" type="number" className="form-control" />
													</div>
													<span style={{ color: "red" }} id="a_x_1_err"></span>
												</div>
												<div className="col-md-2 justify-content-center d-flex flex-column">
													<a className="reset-style" onClick={() => ResetFields('1', 'a')}>Reset</a>
												</div>
											</div>
										</div>
										<div className="col-lg-8">
											<div className="row">
												<div className="col-lg-1 justify-content-center d-flex flex-column">
													<input type="checkbox" className="check-class" id="e_c_1" name="e_c_1" value="1" />
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Evening Start</label>
														<input name="e_s_1" id="e_s_1" placeholder="Date" type="time" className="form-control" onBlur={() => isTimeValid('e_s_1', 'e_e_1')} />
													</div>
													<span style={{ color: "red" }} id="e_s_1_err"></span>
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Evening End</label>
														<input name="e_e_1" id="e_e_1" type="time" className="form-control" onBlur={() => isTimeValid('e_s_1', 'e_e_1')} />
													</div>
													<span style={{ color: "red" }} id="e_e_1_err"></span>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="row">
												<div className="col-md-10">
													<div className="form-group">
														<label>Maximum token</label>
														<input name="e_x_1" id="e_x_1" type="number" className="form-control" />
													</div>
													<span style={{ color: "red" }} id="e_x_1_err"></span>
												</div>
												<div className="col-md-2 justify-content-center d-flex flex-column">
													<a className="reset-style" onClick={() => ResetFields('1', 'e')}>Reset</a>
												</div>
											</div>
										</div>
										<div className="col-lg-12">
											<div className="form-group">
												<input name="date" value="MONDAY" type="text" className="form-control readonly" readOnly />
											</div>
										</div>
										<div className="col-md-8">
											<div className="row">
												<div className="col-lg-1 justify-content-center d-flex flex-column">
													<input type="checkbox" className="check-class" id="m_c_2" name="m_c_2" value="1" />
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Morning Start</label>
														<input name="m_s_2" id="m_s_2" placeholder="Date" type="time" className="form-control" onBlur={() => isTimeValid('m_s_2', 'm_e_2')} />
													</div>
													<span style={{ color: "red" }} id="m_s_2_err"></span>
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Morning End</label>
														<input name="m_e_2" id="m_e_2" type="time" className="form-control" onBlur={() => isTimeValid('m_s_2', 'm_e_2')} />
													</div>
													<span style={{ color: "red" }} id="m_e_2_err"></span>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="row">
												<div className="col-md-10">
													<div className="form-group">
														<label>Maximum token</label>
														<input name="m_x_2" id="m_x_2" type="number" className="form-control" />
													</div>
													<span style={{ color: "red" }} id="m_x_2_err"></span>
												</div>
												<div className="col-md-2 justify-content-center d-flex flex-column">
													<a className="reset-style" onClick={() => ResetFields('2', 'm')}>Reset</a>
												</div>
											</div>
										</div>
										<div className="col-lg-8">
											<div className="row">
												<div className="col-lg-1 justify-content-center d-flex flex-column">
													<input type="checkbox" className="check-class" id="a_c_2" name="a_c_2" value="1" />
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Afternoon Start</label>
														<input name="a_s_2" id="a_s_2" placeholder="Date" type="time" className="form-control" onBlur={() => isTimeValid('a_s_2', 'a_e_2')} />
													</div>
													<span style={{ color: "red" }} id="a_s_2_err"></span>
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Afternoon End</label>
														<input name="a_e_2" id="a_e_2" type="time" className="form-control" onBlur={() => isTimeValid('a_s_2', 'a_e_2')} />
													</div>
													<span style={{ color: "red" }} id="a_e_2_err"></span>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="row">
												<div className="col-md-10">
													<div className="form-group">
														<label>Maximum token</label>
														<input name="a_x_2" id="a_x_2" type="number" className="form-control" />
													</div>
													<span style={{ color: "red" }} id="a_x_2_err"></span>
												</div>
												<div className="col-md-2 justify-content-center d-flex flex-column">
													<a className="reset-style" onClick={() => ResetFields('2', 'a')}>Reset</a>
												</div>
											</div>
										</div>
										<div className="col-lg-8">
											<div className="row">
												<div className="col-lg-1 justify-content-center d-flex flex-column">
													<input type="checkbox" className="check-class" id="e_c_2" name="e_c_2" value="1" />
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Evening Start</label>
														<input name="e_s_2" id="e_s_2" placeholder="Date" type="time" className="form-control" onBlur={() => isTimeValid('e_s_2', 'e_e_2')} />
													</div>
													<span style={{ color: "red" }} id="e_s_2_err"></span>
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Evening End</label>
														<input name="e_e_2" id="e_e_2" type="time" className="form-control" onBlur={() => isTimeValid('e_s_2', 'e_e_2')} />
													</div>
													<span style={{ color: "red" }} id="e_e_2_err"></span>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="row">
												<div className="col-md-10">
													<div className="form-group">
														<label>Maximum token</label>
														<input name="e_x_2" id="e_x_2" type="number" className="form-control" />
													</div>
													<span style={{ color: "red" }} id="e_x_2_err"></span>
												</div>
												<div className="col-md-2 justify-content-center d-flex flex-column">
													<a className="reset-style" onClick={() => ResetFields('2', 'e')}>Reset</a>
												</div>
											</div>
										</div>
										<div className="col-lg-12">
											<div className="form-group">
												<input name="date" value="TUESDAY" type="text" className="form-control readonly" readOnly />
											</div>
										</div>
										<div className="col-md-8">
											<div className="row">
												<div className="col-lg-1 justify-content-center d-flex flex-column">
													<input type="checkbox" className="check-class" id="m_c_3" name="m_c_3" value="1" />
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Morning Start</label>
														<input name="m_s_3" id="m_s_3" placeholder="Date" type="time" className="form-control" onBlur={() => isTimeValid('m_s_3', 'm_e_3')} />
													</div>
													<span style={{ color: "red" }} id="m_s_3_err"></span>
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Morning End</label>
														<input name="m_e_3" id="m_e_3" type="time" className="form-control" onBlur={() => isTimeValid('m_s_3', 'm_e_3')} />
													</div>
													<span style={{ color: "red" }} id="m_e_3_err"></span>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="row">
												<div className="col-md-10">
													<div className="form-group">
														<label>Maximum token</label>
														<input name="m_x_3" id="m_x_3" type="number" className="form-control" />
													</div>
													<span style={{ color: "red" }} id="m_x_3_err"></span>
												</div>
												<div className="col-md-2 justify-content-center d-flex flex-column">
													<a className="reset-style" onClick={() => ResetFields('3', 'm')}>Reset</a>
												</div>
											</div>
										</div>
										<div className="col-lg-8">
											<div className="row">
												<div className="col-lg-1 justify-content-center d-flex flex-column">
													<input type="checkbox" className="check-class" id="a_c_3" name="a_c_3" value="1" />
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Afternoon Start</label>
														<input name="a_s_3" id="a_s_3" placeholder="Date" type="time" className="form-control" onBlur={() => isTimeValid('a_s_3', 'a_e_3')} />
													</div>
													<span style={{ color: "red" }} id="a_s_3_err"></span>
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Afternoon End</label>
														<input name="a_e_3" id="a_e_3" type="time" className="form-control" onBlur={() => isTimeValid('a_s_3', 'a_e_3')} />
													</div>
													<span style={{ color: "red" }} id="a_e_3_err"></span>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="row">
												<div className="col-md-10">
													<div className="form-group">
														<label>Maximum token</label>
														<input name="a_x_3" id="a_x_3" type="number" className="form-control" />
													</div>
													<span style={{ color: "red" }} id="a_x_3_err"></span>
												</div>
												<div className="col-md-2 justify-content-center d-flex flex-column">
													<a className="reset-style" onClick={() => ResetFields('3', 'a')}>Reset</a>
												</div>
											</div>
										</div>
										<div className="col-lg-8">
											<div className="row">
												<div className="col-lg-1 justify-content-center d-flex flex-column">
													<input type="checkbox" className="check-class" id="e_c_3" name="e_c_3" value="1" />
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Evening Start</label>
														<input name="e_s_3" id="e_s_3" placeholder="Date" type="time" className="form-control" onBlur={() => isTimeValid('e_s_3', 'e_e_3')} />
													</div>
													<span style={{ color: "red" }} id="e_s_3_err"></span>
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Evening End</label>
														<input name="e_e_3" id="e_e_3" type="time" className="form-control" />
													</div>
													<span style={{ color: "red" }} id="e_e_3_err"></span>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="row">
												<div className="col-md-10">
													<div className="form-group">
														<label>Maximum token</label>
														<input name="e_x_3" id="e_x_3" type="number" className="form-control" />
													</div>
													<span style={{ color: "red" }} id="e_x_3_err"></span>
												</div>
												<div className="col-md-2 justify-content-center d-flex flex-column">
													<a className="reset-style" onClick={() => ResetFields('3', 'e')}>Reset</a>
												</div>
											</div>
										</div>
										<div className="col-lg-12">
											<div className="form-group">
												<input name="date" value="WEDNESDAY" type="text" className="form-control readonly" readOnly />
											</div>
										</div>
										<div className="col-md-8">
											<div className="row">
												<div className="col-lg-1 justify-content-center d-flex flex-column">
													<input type="checkbox" className="check-class" id="m_c_4" name="m_c_4" value="1" />
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Morning Start</label>
														<input name="m_s_4" id="m_s_4" placeholder="Date" type="time" className="form-control" onBlur={() => isTimeValid('m_s_4', 'm_e_4')} />
													</div>
													<span style={{ color: "red" }} id="m_s_4_err"></span>
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Morning End</label>
														<input name="m_e_4" id="m_e_4" type="time" className="form-control" onBlur={() => isTimeValid('m_s_4', 'm_e_4')} />
													</div>
													<span style={{ color: "red" }} id="m_e_4_err"></span>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="row">
												<div className="col-md-10">
													<div className="form-group">
														<label>Maximum token</label>
														<input name="m_x_4" id="m_x_4" type="number" className="form-control" />
													</div>
													<span style={{ color: "red" }} id="m_x_4_err"></span>
												</div>
												<div className="col-md-2 justify-content-center d-flex flex-column">
													<a className="reset-style" onClick={() => ResetFields('4', 'm')}>Reset</a>
												</div>
											</div>
										</div>
										<div className="col-lg-8">
											<div className="row">
												<div className="col-lg-1 justify-content-center d-flex flex-column">
													<input type="checkbox" className="check-class" id="a_c_4" name="a_c_4" value="1" />
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Afternoon Start</label>
														<input name="a_s_4" id="a_s_4" placeholder="Date" type="time" className="form-control" onBlur={() => isTimeValid('a_s_4', 'a_e_4')} />
													</div>
													<span style={{ color: "red" }} id="a_s_4_err"></span>
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Afternoon End</label>
														<input name="a_e_4" id="a_e_4" type="time" className="form-control" onBlur={() => isTimeValid('a_s_4', 'a_e_4')} />
													</div>
													<span style={{ color: "red" }} id="a_e_4_err"></span>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="row">
												<div className="col-md-10">
													<div className="form-group">
														<label>Maximum token</label>
														<input name="a_x_4" id="a_x_4" type="number" className="form-control" />
													</div>
													<span style={{ color: "red" }} id="a_x_4_err"></span>
												</div>
												<div className="col-md-2 justify-content-center d-flex flex-column">
													<a className="reset-style" onClick={() => ResetFields('4', 'a')}>Reset</a>
												</div>
											</div>
										</div>
										<div className="col-lg-8">
											<div className="row">
												<div className="col-lg-1 justify-content-center d-flex flex-column">
													<input type="checkbox" className="check-class" id="e_c_4" name="e_c_4" value="1" />
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Evening Start</label>
														<input name="e_s_4" id="e_s_4" placeholder="Date" type="time" className="form-control" onBlur={() => isTimeValid('e_s_4', 'e_e_4')} />
													</div>
													<span style={{ color: "red" }} id="e_s_4_err"></span>
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Evening End</label>
														<input name="e_e_4" id="e_e_4" type="time" className="form-control" onBlur={() => isTimeValid('e_s_4', 'e_e_4')} />
													</div>
													<span style={{ color: "red" }} id="e_e_4_err"></span>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="row">
												<div className="col-md-10">
													<div className="form-group">
														<label>Maximum token</label>
														<input name="e_x_4" id="e_x_4" type="number" className="form-control" />
													</div>
													<span style={{ color: "red" }} id="e_x_4_err"></span>
												</div>
												<div className="col-md-2 justify-content-center d-flex flex-column">
													<a className="reset-style" onClick={() => ResetFields('4', 'e')}>Reset</a>
												</div>
											</div>
										</div>

										<div className="col-lg-12">
											<div className="form-group">
												<input name="date" value="THURSDAY" type="text" className="form-control readonly" readOnly />
											</div>
										</div>
										<div className="col-md-8">
											<div className="row">
												<div className="col-lg-1 justify-content-center d-flex flex-column">
													<input type="checkbox" className="check-class" id="m_c_5" name="m_c_5" value="1" />
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Morning Start</label>
														<input name="m_s_5" id="m_s_5" placeholder="Date" type="time" className="form-control" onBlur={() => isTimeValid('m_s_5', 'm_e_5')} />
													</div>
													<span style={{ color: "red" }} id="m_s_5_err"></span>
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Morning End</label>
														<input name="m_e_5" id="m_e_5" type="time" className="form-control" onBlur={() => isTimeValid('m_s_5', 'm_e_5')} />
													</div>
													<span style={{ color: "red" }} id="m_e_5_err"></span>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="row">
												<div className="col-md-10">
													<div className="form-group">
														<label>Maximum token</label>
														<input name="m_x_5" id="m_x_5" type="number" className="form-control" />
													</div>
													<span style={{ color: "red" }} id="m_x_5_err"></span>
												</div>
												<div className="col-md-2 justify-content-center d-flex flex-column">
													<a className="reset-style" onClick={() => ResetFields('5', 'm')}>Reset</a>
												</div>
											</div>
										</div>
										<div className="col-lg-8">
											<div className="row">
												<div className="col-lg-1 justify-content-center d-flex flex-column">
													<input type="checkbox" className="check-class" id="a_c_5" name="a_c_5" value="1" />
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Afternoon Start</label>
														<input name="a_s_5" id="a_s_5" placeholder="Date" type="time" className="form-control" onBlur={() => isTimeValid('a_s_5', 'a_e_5')} />
													</div>
													<span style={{ color: "red" }} id="a_s_5_err"></span>
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Afternoon End</label>
														<input name="a_e_5" id="a_e_5" type="time" className="form-control" onBlur={() => isTimeValid('a_s_5', 'a_e_5')} />
													</div>
													<span style={{ color: "red" }} id="a_e_5_err"></span>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="row">
												<div className="col-md-10">
													<div className="form-group">
														<label>Maximum token</label>
														<input name="a_x_5" id="a_x_5" type="number" className="form-control" />
													</div>
													<span style={{ color: "red" }} id="a_x_5_err"></span>
												</div>
												<div className="col-md-2 justify-content-center d-flex flex-column">
													<a className="reset-style" onClick={() => ResetFields('5', 'a')}>Reset</a>
												</div>
											</div>
										</div>
										<div className="col-lg-8">
											<div className="row">
												<div className="col-lg-1 justify-content-center d-flex flex-column">
													<input type="checkbox" className="check-class" id="e_c_5" name="e_c_5" value="1" />
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Evening Start</label>
														<input name="e_s_5" id="e_s_5" placeholder="Date" type="time" className="form-control" onBlur={() => isTimeValid('e_s_5', 'e_e_5')} />
													</div>
													<span style={{ color: "red" }} id="e_s_5_err"></span>
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Evening End</label>
														<input name="e_e_5" id="e_e_5" type="time" className="form-control" onBlur={() => isTimeValid('e_s_5', 'e_e_5')} />
													</div>
													<span style={{ color: "red" }} id="e_e_5_err"></span>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="row">
												<div className="col-md-10">
													<div className="form-group">
														<label>Maximum token</label>
														<input name="e_x_5" id="e_x_5" type="number" className="form-control" />
													</div>
													<span style={{ color: "red" }} id="e_x_5_err"></span>
												</div>
												<div className="col-md-2 justify-content-center d-flex flex-column">
													<a className="reset-style" onClick={() => ResetFields('5', 'e')}>Reset</a>
												</div>
											</div>
										</div>

										<div className="col-lg-12">
											<div className="form-group">
												<input name="date" value="FRIDAY" type="text" className="form-control readonly" readOnly />
											</div>
										</div>
										<div className="col-md-8">
											<div className="row">
												<div className="col-lg-1 justify-content-center d-flex flex-column">
													<input type="checkbox" className="check-class" id="m_c_6" name="m_c_6" value="1" />
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Morning Start</label>
														<input name="m_s_6" id="m_s_6" placeholder="Date" type="time" className="form-control" onBlur={() => isTimeValid('m_s_6', 'm_e_6')} />
													</div>
													<span style={{ color: "red" }} id="m_s_6_err"></span>
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Morning End</label>
														<input name="m_e_6" id="m_e_6" type="time" className="form-control" onBlur={() => isTimeValid('m_s_6', 'm_e_6')} />
													</div>
													<span style={{ color: "red" }} id="m_e_6_err"></span>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="row">
												<div className="col-md-10">
													<div className="form-group">
														<label>Maximum token</label>
														<input name="m_x_6" id="m_x_6" type="number" className="form-control" />
													</div>
													<span style={{ color: "red" }} id="m_x_6_err"></span>
												</div>
												<div className="col-md-2 justify-content-center d-flex flex-column">
													<a className="reset-style" onClick={() => ResetFields('6', 'm')}>Reset</a>
												</div>
											</div>
										</div>
										<div className="col-lg-8">
											<div className="row">
												<div className="col-lg-1 justify-content-center d-flex flex-column">
													<input type="checkbox" className="check-class" id="a_c_6" name="a_c_6" value="1" />
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Afternoon Start</label>
														<input name="a_s_6" id="a_s_6" placeholder="Date" type="time" className="form-control" onBlur={() => isTimeValid('a_s_6', 'a_e_6')} />
													</div>
													<span style={{ color: "red" }} id="a_s_6_err"></span>
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Afternoon End</label>
														<input name="a_e_6" id="a_e_6" type="time" className="form-control" onBlur={() => isTimeValid('a_s_6', 'a_e_6')} />
													</div>
													<span style={{ color: "red" }} id="a_e_6_err"></span>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="row">
												<div className="col-md-10">
													<div className="form-group">
														<label>Maximum token</label>
														<input name="a_x_6" id="a_x_6" type="number" className="form-control" />
													</div>
													<span style={{ color: "red" }} id="a_x_6_err"></span>
												</div>
												<div className="col-md-2 justify-content-center d-flex flex-column">
													<a className="reset-style" onClick={() => ResetFields('6', 'a')}>Reset</a>
												</div>
											</div>
										</div>
										<div className="col-lg-8">
											<div className="row">
												<div className="col-lg-1 justify-content-center d-flex flex-column">
													<input type="checkbox" className="check-class" id="e_c_6" name="e_c_6" value="1" />
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Evening Start</label>
														<input name="e_s_6" id="e_s_6" placeholder="Date" type="time" className="form-control" onBlur={() => isTimeValid('e_s_6', 'e_e_6')} />
													</div>
													<span style={{ color: "red" }} id="e_s_6_err"></span>
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Evening End</label>
														<input name="e_e_6" id="e_e_6" type="time" className="form-control" onBlur={() => isTimeValid('e_s_6', 'e_e_6')} />
													</div>
													<span style={{ color: "red" }} id="e_e_6_err"></span>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="row">
												<div className="col-md-10">
													<div className="form-group">
														<label>Maximum token</label>
														<input name="e_x_6" id="e_x_6" type="number" className="form-control" />
													</div>
													<span style={{ color: "red" }} id="e_x_6_err"></span>
												</div>
												<div className="col-md-2 justify-content-center d-flex flex-column">
													<a className="reset-style" onClick={() => ResetFields('6', 'e')}>Reset</a>
												</div>
											</div>
										</div>
										<div className="col-lg-12">
											<div className="form-group">
												<input name="date" value="SATURDAY" type="text" className="form-control readonly" readOnly />
											</div>
										</div>
										<div className="col-md-8">
											<div className="row">
												<div className="col-lg-1 justify-content-center d-flex flex-column">
													<input type="checkbox" className="check-class" id="m_c_7" name="m_c_7" value="1" />
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Morning Start</label>
														<input name="m_s_7" id="m_s_7" placeholder="Date" type="time" className="form-control" onBlur={() => isTimeValid('m_s_7', 'm_e_7')} />
													</div>
													<span style={{ color: "red" }} id="m_s_7_err"></span>
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Morning End</label>
														<input name="m_e_7" id="m_e_7" type="time" className="form-control" onBlur={() => isTimeValid('m_s_7', 'm_e_7')} />
													</div>
													<span style={{ color: "red" }} id="m_e_7_err"></span>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="row">
												<div className="col-md-10">
													<div className="form-group">
														<label>Maximum token</label>
														<input name="m_x_7" id="m_x_7" type="number" className="form-control" />
													</div>
													<span style={{ color: "red" }} id="m_x_7_err"></span>
												</div>
												<div className="col-md-2 justify-content-center d-flex flex-column">
													<a className="reset-style" onClick={() => ResetFields('7', 'm')}>Reset</a>
												</div>
											</div>
										</div>
										<div className="col-lg-8">
											<div className="row">
												<div className="col-lg-1 justify-content-center d-flex flex-column">
													<input type="checkbox" className="check-class" id="a_c_7" name="a_c_7" value="1" />
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Afternoon Start</label>
														<input name="a_s_7" id="a_s_7" placeholder="Date" type="time" className="form-control" onBlur={() => isTimeValid('a_s_7', 'a_e_7')} />
													</div>
													<span style={{ color: "red" }} id="a_s_7_err"></span>
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Afternoon End</label>
														<input name="a_e_7" id="a_e_7" type="time" className="form-control" onBlur={() => isTimeValid('a_s_7', 'a_e_7')} />
													</div>
													<span style={{ color: "red" }} id="a_e_7_err"></span>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="row">
												<div className="col-md-10">
													<div className="form-group">
														<label>Maximum token</label>
														<input name="a_x_7" id="a_x_7" type="number" className="form-control" />
													</div>
													<span style={{ color: "red" }} id="a_x_7_err"></span>
												</div>
												<div className="col-md-2 justify-content-center d-flex flex-column">
													<a className="reset-style" onClick={() => ResetFields('7', 'a')}>Reset</a>
												</div>
											</div>
										</div>
										<div className="col-lg-8">
											<div className="row">
												<div className="col-lg-1 justify-content-center d-flex flex-column">
													<input type="checkbox" className="check-class" id="e_c_7" name="e_c_7" value="1" />
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Evening Start</label>
														<input name="e_s_7" id="e_s_7" placeholder="Date" type="time" className="form-control" onBlur={() => isTimeValid('e_s_7', 'e_e_7')} />
													</div>
													<span style={{ color: "red" }} id="e_s_7_err"></span>
												</div>
												<div className="col-lg-5">
													<div className="form-group">
														<label>Evening End</label>
														<input name="e_e_7" id="e_e_7" type="time" className="form-control" onBlur={() => isTimeValid('e_s_7', 'e_e_7')} />
													</div>
													<span style={{ color: "red" }} id="e_e_7_err"></span>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="row">
												<div className="col-md-10">
													<div className="form-group">
														<label>Maximum token</label>
														<input name="e_x_7" id="e_x_7" type="number" className="form-control" />
													</div>
													<span style={{ color: "red" }} id="e_x_7_err"></span>
												</div>
												<div className="col-md-2 justify-content-center d-flex flex-column">
													<a className="reset-style" onClick={() => ResetFields('7', 'e')}>Reset</a>
												</div>
											</div>
										</div>

									</div>

									<a className="btn btn-main btn-round-full" onClick={WeeklyAvailabilitySetting} style={{ color: "white" }}>Save data<i className="icofont-simple-right ml-2"></i></a>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
export default WeeklyAvailabilityComponent;