import '../Style.css';
import ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';
import ListBookingComponent from './ListBookingComponent';
import Loader from './loader';
import { createRoot } from 'react-dom/client';
import RestHelper from '../RestHelper';
function TokenBookingListComponent() {
  const [loading,IsLoad] = useState(true);
    useEffect(() => {
      BookedToken();
    });
    async function BookedToken() {
      let session_key = localStorage.getItem('session_key');

      let container = document.getElementById('list');
      let list = createRoot(container);  // Create a root.
      let apiname = "getTokenStatus";
      let method = "post";
      let body = {
        date: ''
      };
      try {
        let response = await RestHelper(apiname, method, body);
        if (response) {
          IsLoad(false);
          list.render(<ListBookingComponent object={response.customDataObject} />);
        }
      } catch (err) {
        console.log(err);
        IsLoad(false);
      }
    }
    const loader = loading?<Loader />:null;
    return (
        <>
        {loader}
          <section className="page-title bg-1">
              <div className="overlay"></div>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="block text-center">
                      <h1 className="text-capitalize mb-5 text-lg">My booked tokens</h1>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="container top-bottom-spacing">
                <div className="row justify-content-center">
                <div className="col-md-8">
                    <ul className="post-job-bx" id="list">
                    
                    </ul>
                </div>
                </div>
            </div>
        </>
    );
}
export default TokenBookingListComponent;