function DefaultSearchDisplay(data) {
    const message = data.message;
    return (
        <div className="row d-flex h-100" align="center">
             <div className="col-md-12 justify-content-center align-self-center" style={{padding:"10px"}}>
                 <h5 className="mb-3 d-flex flex-column" id="use_search">{message}</h5>
                 <img src="../assets/images/search1.png" />
             </div>
        </div>
    );
}
export default DefaultSearchDisplay;