import Header from '../Components/header';
import Footer from '../Components/footer';
import DetailedSearchContent from '../Components/detailedsearchcontent';

function DetailedSearch() {
  return (
    <>
      <Header /><DetailedSearchContent /><Footer />
    </>
  );
}
export default DetailedSearch;