import Header from '../Components/header';
import Footer from '../Components/footer';
import ClientProfileContent from '../Components/clientprofilecontent';

function ClientProfile() {
  return (
    <>
      <Header /><ClientProfileContent /><Footer />
    </>
  );
}
export default ClientProfile;