import Header from '../Components/header';
import Footer from '../Components/footer';
import TokenDetailsComponent from '../Components/tokenDetailsComponent';

function TokenDetails() {
  return (
    <>
      <Header /><TokenDetailsComponent /><Footer />
    </>
  );
}
export default TokenDetails;