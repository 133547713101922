import Header from '../Components/header';
import Footer from '../Components/footer';
import CustomerLoginComponent from '../Components/customerloginComponent';

function CustomerLogin() {
  return (
    <>
      <Header /><CustomerLoginComponent /><Footer />
    </>
  );
}
export default CustomerLogin;