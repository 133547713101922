/* eslint-disable react/no-typos */
import CardComponent from './cardComponent';
import NoDataFound from './nodatafound';
import DefaultSearchDisplay from './defaultSearchDisplay';
import Loader from './loader';
import '../Style.css';
import moment from 'moment'
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import RestHelper from '../RestHelper';

// minimum date setting

let minDate = moment().format('YYYY-MM-DD');
let maxDate = moment().add(30, 'days').format('YYYY-MM-DD');
function DetailedSearchContent() {
    const [loading, IsLoad] = useState(false);
    const [sch_date, setDate] = useState(minDate);
    const [sch_location, setLocation] = useState('');
    const [sch_category, setCategory] = useState('doctor');
    const [sch_specialization, setSpecialization] = useState('');
    const [sch_data, setData] = useState('');

    // states for selectbox
    const [categories, setCategories] = useState('');
    const [specializations, setSpecializations] = useState('');

    let params = useParams();
    let url_date = params.searchdate;
    let url_cat = params.category;
    let url_sub = params.specialization;

    useEffect(() => {
        IsLoad(true);
        getCategories();
        getProfileData();
    }, []);

    async function getProfileData() {
        let sch_date_param = url_date;
        let container = document.getElementById('appendResult');
        let root = createRoot(container);  // Create a root.
        let apiname = "getProfileInfo";
        let method = "post";
        let body = {
            sch_date: sch_date_param,
            sch_category: url_cat,
            sch_specialization: url_sub
        };
        try {
            let response = await RestHelper(apiname, method, body);
            if (response) {
                let time = response.time;
                let date = response.date;
                response = response.result;
                if (sch_date_param !== '' && sch_date_param != undefined) {
                    if (response.length) {
                        setDate(sch_date_param);

                        // render serach profiles
                        root.render(
                            <CardComponent
                                c_name={response}
                                date={sch_date_param}
                                category={sch_date}
                                specialization={sch_specialization}
                                currentdate={date}
                                currenttime={time}
                            />
                        );
                    } else {
                        root.render(<NoDataFound />);
                    }
                }
                else {
                    root.render(<DefaultSearchDisplay message="Use search box to search for available services" />);
                }
            }
            IsLoad(false);
        } catch (err) {
            console.log(err);
            IsLoad(false);
        }
    }

    async function getCategories() {
        let apiname = "getCategories";
        let method = "post";
        let body = {
            date: ''
        };
        try {
            let response = await RestHelper(apiname, method, body);
            if (response) {
                // response = response.result;
                // for(var i=0; i < response.length; i++) {
                //     setCategory(response[0].job_id);
                //     var option = document.createElement('option');
                //     option.value = response[i].job_id;
                //     option.innerHTML = response[i].job_name;
                //     document.getElementById('categories').appendChild(option);
                // } 
                // getSpecialization();
                response = response.result;
                let selectArray = [];
                for (var i = 0; i < response.length; i++) {
                    let id = response[i].job_id;
                    let label = response[i].job_name;
                    selectArray.push({ id: id, label: label });
                    setCategory(response[0].job_id);
                }
                setCategories(selectArray);
                getSpecialization(response[0].job_id);
            }
        } catch (err) {
            console.log(err);
        }
    }
    async function getSpecialization(job_id) {
        let apiname = "getSpecialization";
        let method = "post";
        let body = {
            category: job_id
        };
        try {
            let response = await RestHelper(apiname, method, body);
            if (response) {
                response = response.result;
                let selectArray = [{ id: '', label: 'Select Specialization' }];
                for (var i = 0; i < response.length; i++) {
                    let id = response[i].job_specialization_id;
                    let label = response[i].specialization;
                    selectArray.push({ id: id, label: label });
                }
                setSpecializations(selectArray);
                IsLoad(false);
            }
        } catch (err) {
            console.log(err);
        }
    }
    async function getSearchData() {
        let container = document.getElementById('appendResult');
        let root = createRoot(container);  // Create a root.
        //root.unmount();
        IsLoad(true);
        let apiname = "search";
        let method = "post";
        let body = {
            sch_data: sch_data,
            sch_location: sch_location,
            sch_category: sch_category,
            sch_specialization: sch_specialization,
            sch_date: sch_date
        };
        try {
            let response = await RestHelper(apiname, method, body);
            if (response) {
                IsLoad(false);
                let date = response.date;
                let time = response.time;
                response = response.result;
                if (response.length) {
                    root.render(<CardComponent c_name={response} date={sch_date} currentdate={date} currenttime={time} />);
                } else {
                    root.render(<NoDataFound />);
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    const loader = loading ? <Loader /> : null;
    return (
        <>
            <section className='search-wrapper'>
                <h3 className='search-service-head'>Search services</h3>
                <div className='search-container'>
                    <div className='search-underline'>
                        <h4 className='search-here'>Search here,</h4>
                    </div>
                    <form className="search-form">
                        <div className="row">
                            <div className="col-md-2 col-6">
                                <input type="date" className="form-control search-input-fields custom-date-input" id="date" min={minDate} max={maxDate} name="sch_date" value={sch_date} onChange={e => setDate(e.target.value)} />
                                <span style={{ color: "white" }} id="err_date"></span>
                            </div>
                            <div className="col-md-3 col-6">
                                <input type="text" className="form-control search-input-fields" id="data" placeholder="What are you looking for..." name="sch_data" onChange={e => setData(e.target.value)} />
                            </div>
                            <div className="col-md-2 col-6">
                                <input type="text" className="form-control search-input-fields" id="location" placeholder="Search by location" name="sch_location" onChange={e => setLocation(e.target.value)} />
                            </div>
                            <div className="col-md-2 col-6">
                                <select className="form-control search-input-fields" id="categories" name="sch_category" onChange={e => { setCategory(e.target.value); getSpecialization(e.target.value); }}>
                                    {categories.length > 0 && categories.map((cts, idx) => (
                                        <option key={idx} value={cts.id}>{cts.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-2 col-6">
                                <select className="form-control search-input-fields" id="specialization" onChange={e => setSpecialization(e.target.value)}>
                                    {specializations.length > 0 && specializations.map((sps, idx) => (
                                        <option key={idx} value={sps.id}>{sps.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-1 col-6">
                                <button onClick={getSearchData} type="button" className="btn btn-main-2 btn-round-full btn-icon search-btn-doctor">Search</button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>

            <section className="section blog-wrap search-section">
                <div className="container" id="container_search">
                    <div className="row">

                        <div className="col-lg-12">
                            {loader}
                            <span id="appendResult">
                                {/* search data */}
                            </span>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default DetailedSearchContent;