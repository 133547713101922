import React, { useRef, useState } from "react";
import "./OTPInput.css";

const OTPInput = ({ length = 4, onComplete }) => {
  const inputRef = useRef([]);
  const [OTP, setOTP] = useState(Array(length).fill(""));

  const handleTextChange = (input, index) => {
    const newPin = [...OTP];
    newPin[index] = input;
    setOTP(newPin);

    if (input.length === 1 && index < length - 1) {
      inputRef.current[index + 1]?.focus();
    } else if (input.length === 0 && index > 0) {
      inputRef.current[index - 1]?.focus();
    }

    if (newPin.every((digit) => digit !== "")) {
      onComplete(newPin.join(""));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && OTP[index] === "") {
      if (index > 0) {
        inputRef.current[index - 1]?.focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").slice(0, length);
    const newPin = OTP.map((_, i) => pasteData[i] || "");
    setOTP(newPin);

    newPin.forEach((value, i) => {
      inputRef.current[i].value = value;
    });

    const filledIndex =
      pasteData.length >= length ? length - 1 : pasteData.length;
    inputRef.current[filledIndex]?.focus();

    if (newPin.every((digit) => digit !== "")) {
      onComplete(newPin.join(""));
    }
  };

  return (
    <div className="otp-input-container">
      {Array.from({ length }, (_, index) => (
        <input
          key={index}
          type="text"
          maxLength={1}
          value={OTP[index]}
          onChange={(e) => handleTextChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          ref={(ref) => (inputRef.current[index] = ref)}
          className="otp-input"
        />
      ))}
    </div>
  );
};

export default OTPInput;
