import Header from '../Components/header';
import Footer from '../Components/footer';
import TokenInfoComponent from '../Components/tokenInfoComponent';

function TokenInfo() {
  return (
    <>
      <Header /><TokenInfoComponent /><Footer />
    </>
  );
}
export default TokenInfo;