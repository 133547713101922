import Header from '../Components/header';
import Footer from '../Components/footer';
import WeeklyAvailabilityComponent from '../Components/weeklyavailabilityComponent';

function WeeklyAvailability() {
  return (
    <>
      <Header /><WeeklyAvailabilityComponent /><Footer />
    </>
  );
}
export default WeeklyAvailability;